.container {
    z-index: 1;
    background-color: white;
}

.holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 1521px;
    margin-bottom: 90px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    flex-wrap: wrap;
    color: black;
    font-size: 32px;
    font-weight: 600;
}

.contentAdd {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

}

.app {
    max-width: 400px;
    cursor: pointer;
    width: 100%;
    transition: .2s ease;
}

.app:hover {
    transition: .2s ease;
    transform: scale(1.02);
}